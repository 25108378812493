import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Card to card transfer"
        keywords={[`card`, `peer-to-peer`, `p2p`, `transfer`, `money`, `payment`, `checkout`]}
      />

    </Layout>
  );
}

export default IndexPage;
